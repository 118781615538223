<template>
    <div class="space-y-6">
        <div class="text-lg font-medium text-gray-600">
            We found some customers that match your email and/or date of birth.
            Please choose the correct one or click
            <span class="font-bold">I Don't See Myself</span> to create a new
            one.
        </div>

        <div class="space-y-1">
            <div class="grid grid-cols-5 items-end">
                <div />

                <div class="text-sm font-medium">Name</div>

                <div class="text-sm font-medium">Date of Birth</div>

                <div class="text-sm font-medium">Home Facility</div>

                <div class="text-sm font-medium">Last Activity</div>

                <div class="col-span-5 h-px bg-gray-200" />
            </div>

            <div
                v-for="customer in chooseCustomerData.customers"
                :key="customer.customerId"
                class="grid grid-cols-5 items-center gap-x-2"
            >
                <prime-button
                    link
                    :loading="isPendingChooseCustomer"
                    :disabled="isPendingCreateCustomer"
                    @click="
                        handleChooseCustomer(
                            customer.customerId,
                            customer.homeFacilityId
                        )
                    "
                    >This is Me</prime-button
                >

                <div>{{ customer.firstName }} {{ customer.lastInitial }}.</div>

                <div>
                    {{ customer.dateOfBirth }}
                </div>

                <div>
                    {{ customer.homeFacilityName }}
                </div>

                <div>
                    {{ customer.lastActivity }}
                </div>

                <div class="col-span-5 h-px bg-gray-200" />
            </div>

            <div
                v-for="customer in chooseCustomerData.nullDateOfBirthCustomers"
                :key="customer.customerId"
                class="grid grid-cols-5 items-center gap-x-2"
            >
                <prime-button
                    link
                    :loading="isPendingChooseCustomer"
                    :disabled="isPendingCreateCustomer"
                    @click="
                        handleChooseCustomer(
                            customer.customerId,
                            customer.homeFacilityId
                        )
                    "
                    >This is Me</prime-button
                >

                <div>{{ customer.firstName }} {{ customer.lastInitial }}.</div>

                <div class="text-gray-500">&mdash;</div>

                <div>
                    {{ customer.homeFacilityName }}
                </div>

                <div>
                    {{ customer.lastActivity }}
                </div>

                <div class="col-span-5 h-px bg-gray-200" />
            </div>
        </div>

        <div class="flex justify-end">
            <prime-button
                label="I Don't See Myself"
                :disabled="isPendingChooseCustomer"
                :loading="isPendingCreateCustomer"
                @click="handleCreateCustomer"
            />
        </div>
    </div>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const PossibleCustomerFragment = graphql(/* GraphQL */ `
    fragment PossibleCustomerFragment on VerifyDateOfBirthMultipleResult {
        token
        customers {
            customerId
            firstName
            lastInitial
            homeFacilityId
            homeFacilityName
            dateOfBirth
            lastActivity
        }
        nullDateOfBirthCustomers {
            customerId
            firstName
            lastInitial
            homeFacilityId
            homeFacilityName
            dateOfBirth
            lastActivity
        }
    }
`)
</script>

<script setup lang="ts">
import { computed, ref } from 'vue'
import PrimeButton from 'primevue/button'
import { useMutation } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import type {
    AuthChooseCustomerInput,
    AuthNewCustomerInput
} from '~/resources/graphql/graphql'
import { useSetViewer } from '~/composables/use-login'

const props = defineProps<{
    chooseCustomer: FragmentType<typeof PossibleCustomerFragment>
}>()

const emit = defineEmits<{ (e: 'done'): void }>()

const chooseCustomerData = computed(() =>
    getFragmentData(PossibleCustomerFragment, props.chooseCustomer)
)

const selectedFacilityId = ref()
const setViewer = useSetViewer()
const query = useRpQuery()
const { mutate: mutateChooseCustomer, isPending: isPendingChooseCustomer } =
    useMutation({
        mutationFn: (input: AuthChooseCustomerInput) =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation AuthChooseCustomer(
                        $input: AuthChooseCustomerInput!
                    ) {
                        authChooseCustomer(input: $input) {
                            __typename
                            ... on AuthChooseCustomerResult {
                                recordId
                                record {
                                    ...ViewerFragment
                                }
                            }
                            ... on ValidationError {
                                fieldErrors {
                                    message
                                }
                            }
                        }
                    }
                `),
                {
                    input
                }
            )
    })
function handleChooseCustomer(customerId: string, facilityId: string) {
    selectedFacilityId.value = facilityId
    mutateChooseCustomer(
        {
            token: chooseCustomerData.value.token,
            customerId
        },
        {
            onSuccess: data => {
                if (
                    data.authChooseCustomer.__typename ===
                    'AuthChooseCustomerResult'
                ) {
                    setViewer(data.authChooseCustomer.record)
                    emit('done')
                    return
                }

                throw new Error()
            }
        }
    )
}

const { mutate: mutateCreateCustomer, isPending: isPendingCreateCustomer } =
    useMutation({
        mutationFn: (input: AuthNewCustomerInput) =>
            query(
                graphql(/** @lang GraphQL */ `
                    mutation AuthNewCustomer($input: AuthNewCustomerInput!) {
                        authNewCustomer(input: $input) {
                            __typename
                            ... on AuthNewCustomerResult {
                                recordId
                                record {
                                    ...ViewerFragment
                                }
                            }
                        }
                    }
                `),
                {
                    input
                }
            )
    })
function handleCreateCustomer() {
    mutateCreateCustomer(
        {
            token: chooseCustomerData.value.token
        },
        {
            onSuccess: data => {
                if (
                    data.authNewCustomer.__typename === 'AuthNewCustomerResult'
                ) {
                    setViewer(data.authNewCustomer.record)
                    emit('done')
                    return
                }

                throw new Error()
            }
        }
    )
}
</script>
