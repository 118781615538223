<template>
    <div class="space-y-6">
        <div>
            <span class="font-medium text-gray-600"
                >Please enter the code sent to:</span
            >
            <div class="mt-1 flex items-center">
                <template v-if="phone !== undefined && phone !== ''">
                    <i class="pi pi-phone text-gray-600"></i>
                    <span class="text-900 ml-2 font-bold">{{ phone }}</span>
                </template>
                <template v-else>
                    <i class="pi pi-envelope text-gray-600"></i>
                    <span class="text-900 ml-2 font-bold">{{ email }}</span>
                </template>
            </div>
        </div>
        <div ref="codeRef">
            <input-text
                :model-value="code"
                placeholder="XXXXXX"
                inputmode="numeric"
                autocomplete="one-time-code"
                class="!text-[16px]"
                :disabled="isPending"
                @update:model-value="emit('update:code', $event)"
                @keydown.enter="verifyCode"
            />
            <div
                v-if="incorrectCodeMessage !== undefined"
                class="text-red-600 font-medium"
            >
                {{ incorrectCodeMessage }}
            </div>
        </div>
        <div class="flex w-full flex-col gap-2 sm:flex-row">
            <prime-button
                label="Cancel"
                class="flex-1"
                outlined
                @click="cancel"
            />
            <prime-button
                label="Verify"
                class="flex-1"
                :loading="isPending"
                @click="verifyCode"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue'
import PrimeButton from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useRpQuery, useSetViewer, useShowAlert } from '#imports'
import { useMutation } from '@tanstack/vue-query'
import { graphql } from '~/resources/graphql'
import type { VerifyInput } from '~/resources/graphql/graphql'

const props = defineProps<{
    code: string
    token: string
    phone: string | undefined
    email: string | undefined
}>()

const emit = defineEmits<{
    (e: 'update:code', payload: string): void
    (e: 'done'): void
    (e: 'cancel'): void
    (e: 'collectName', payload: { id: string; homeFacilityId: string }): void
    (e: 'collectDateOfBirth', payload: string): void
}>()

const codeRef = ref<HTMLDivElement>()
onMounted(() => {
    codeRef.value?.querySelector('input')?.focus?.()
})

function selectCodeInput() {
    setTimeout(() => {
        codeRef.value?.querySelector('input')?.select?.()
    }, 50)
}

// Only perform the auto-submit on the first go
const attemptedCode = ref(false)
watchEffect(() => {
    if (attemptedCode.value === false && /^\d{6}$/.test(props.code)) {
        verifyCode()
    }
})

const query = useRpQuery({ orgLevel: true })
const { mutate, isPending } = useMutation({
    mutationFn: (input: VerifyInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation Verify($input: VerifyInput!) {
                    verify(input: $input) {
                        __typename
                        ... on VerifyResult {
                            record {
                                ...ViewerFragment
                                primaryCustomer {
                                    id
                                    firstName
                                    lastName
                                    homeFacility {
                                        id
                                    }
                                }
                            }
                        }
                        ... on VerifyDateOfBirthChallengeResult {
                            token
                        }
                        ... on IncorrectCodeProblem {
                            message
                        }
                        ... on ValidationError {
                            fieldErrors {
                                path
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const showAlert = useShowAlert()
const incorrectCodeMessage = ref()
const setViewer = useSetViewer()
function verifyCode() {
    attemptedCode.value = true
    if (isPending.value) {
        return false
    }

    if (props.code === '') {
        incorrectCodeMessage.value =
            'Invalid code entered. Please check the code and try again.'
        selectCodeInput()
        return
    }

    // TODO: Add warning on invalid data
    mutate(
        {
            token: props.token,
            code: props.code
        },
        {
            onSuccess: data => {
                if (data.verify.__typename === 'VerifyResult') {
                    setViewer(data.verify.record)
                    // If empty customer, try to fill in the name now.
                    if (
                        data.verify.record.primaryCustomer.firstName === '' &&
                        data.verify.record.primaryCustomer.lastName === ''
                    ) {
                        emit('collectName', {
                            id: data.verify.record.primaryCustomer.id,
                            homeFacilityId:
                                data.verify.record.primaryCustomer.homeFacility
                                    .id
                        })
                    }
                    // Otherwise we are just done
                    else {
                        emit('done')
                    }
                }
                // If birthday challenge switch to that
                else if (
                    data.verify.__typename ===
                    'VerifyDateOfBirthChallengeResult'
                ) {
                    emit('collectDateOfBirth', data.verify.token)
                } else if (data.verify.__typename === 'IncorrectCodeProblem') {
                    incorrectCodeMessage.value = data.verify.message
                    selectCodeInput()
                } else if (data.verify.__typename === 'ValidationError') {
                    incorrectCodeMessage.value =
                        'Invalid code entered. Please check the code and try again.'
                    selectCodeInput()
                } else {
                    showAlert(
                        'Uh Oh!',
                        'Something went wrong. Please try again and contact the facility if the issue continues.'
                    )
                }
            }
        }
    )
}

function cancel() {
    emit('cancel')
}
</script>
