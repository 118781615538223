<template>
    <div
        v-show="showRecaptcha"
        class="space-y-6"
    >
        <div class="mb-4">
            <span class="text-lg font-medium text-gray-600"
                >To continue as a guest, please complete the following
                captcha:</span
            >
        </div>

        <rp-recaptcha
            v-if="showRecaptcha"
            @token="recaptchaToken = $event"
        />

        <prime-button
            label="Continue"
            class="w-full"
            :disabled="recaptchaDisabled"
            :loading="isVerifyingGuest"
            @click="submitGuest"
        />
    </div>

    <div v-if="showRecaptcha" />

    <div
        v-else-if="token === undefined"
        class="px-3"
    >
        <form @submit="onSubmit">
            <div class="mb-4">
                <span class="text-lg font-medium text-gray-600"
                    >Please enter your phone number or email address to receive
                    a verification code.</span
                >
            </div>
            <div class="flex flex-col gap-4">
                <div>
                    <phone-number
                        v-bind="phoneValue"
                        initial-value=""
                        autofocus
                    />
                    <span
                        v-if="errors.phone"
                        id="email-error"
                        class="text-small text-red-600"
                        >{{ errors.phone }}</span
                    >
                </div>
                <span
                    class="-my-2 w-full text-center font-medium text-gray-500"
                >
                    - OR -
                </span>
                <div>
                    <span class="p-input-icon-left w-full">
                        <i class="pi pi-envelope"></i>
                        <input-text
                            id="email"
                            v-bind="emailValue"
                            inputmode="email"
                            type="email"
                            class="w-full !text-[16px]"
                            :class="{ 'p-invalid': errors.email }"
                            placeholder="Email Address"
                            aria-describedby="email-error"
                        />
                    </span>
                    <span
                        v-if="errors.email"
                        id="email-error"
                        class="text-small text-red-600"
                        >{{ errors.email }}</span
                    >
                </div>
                <div
                    v-if="submitCount > 0 && errors[''] !== undefined"
                    class="text-red-600 font-medium"
                >
                    {{ errors[''] }}
                </div>
                <div
                    v-if="!isKiosk"
                    class="flex flex-wrap gap-3"
                >
                    <div>
                        <checkbox
                            v-bind="rememberMe"
                            input-id="remember_me"
                            binary
                            class="mr-2"
                        />
                        <label
                            for="remember_me"
                            class="text-900 mr-8 font-medium"
                        >
                            Remember Me
                        </label>
                    </div>
                </div>

                <div class="flex items-center gap-4">
                    <prime-button
                        v-if="showGuest"
                        outlined
                        label="Continue as Guest"
                        class="w-full"
                        :loading="isPending"
                        @click="handleRecaptcha"
                    />

                    <prime-button
                        type="submit"
                        label="Sign In"
                        class="w-full"
                        :loading="isPending"
                    />
                </div>
            </div>
        </form>
    </div>

    <choose-customer
        v-else-if="chooseCustomerData !== undefined"
        :choose-customer="chooseCustomerData"
        @done="done"
    />

    <date-of-birth-challenge
        v-else-if="dateOfBirthToken !== undefined"
        :token="dateOfBirthToken"
        @done="done"
        @multiple="chooseCustomerData = $event"
    />

    <pin-verification
        v-else
        v-model:code="twoFactorCode"
        :token="token"
        :phone="phoneValue.modelValue?.formatted"
        :email="emailValue.modelValue"
        @cancel="token = undefined"
        @done="done"
        @collect-name="primaryCustomer = $event"
        @collect-date-of-birth="dateOfBirthToken = $event"
    />
</template>

<script setup lang="ts">
import { boolean, object, string } from 'yup'
import { toTypedSchema } from '@vee-validate/yup'
import { computed, inject, onMounted, onUnmounted, ref } from 'vue'
import { useForm } from 'vee-validate'
import PinVerification from '~/components/Verification/PinVerification.vue'
import PrimeButton from 'primevue/button'
import InputText from 'primevue/inputtext'
import Checkbox from 'primevue/checkbox'
import { useMutation } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import { type FragmentType, graphql } from '~/resources/graphql'
import type {
    LoginByEmailInput,
    LoginByPhoneInput,
    VerifyGuestInput
} from '~/resources/graphql/graphql'
import { useToast } from 'primevue/usetoast'
import { useViewer } from '~/composables/use-login'
import PhoneNumber from '~/components/Input/PhoneNumber.vue'
import ChooseCustomer, {
    PossibleCustomerFragment
} from '~/components/Verification/ChooseCustomer.vue'
import DateOfBirthChallenge from '~/components/Verification/DateOfBirthChallenge.vue'
import { useLayoutMode } from '~/composables/use-layout-mode'
import RpRecaptcha from '~/components/Verification/RpRecaptcha.vue'

const emit = defineEmits<{
    (e: 'close'): void
    (e: 'guest'): void
}>()

const dialogRef = inject('dialogRef', null)
const showGuest = computed(() => dialogRef?.value?.data.showGuest ?? false)
const showRecaptcha = ref(false)
const recaptchaToken = ref('')

const { isKiosk } = useLayoutMode()
const token = ref<string>()
const dateOfBirthToken = ref<string>()
const primaryCustomer = ref<{ id: string; homeFacilityId: string }>()
const chooseCustomerData = ref<FragmentType<typeof PossibleCustomerFragment>>()

const schema = toTypedSchema(
    object({
        phone: object()
            .shape({
                formatted: string(),
                number: string(),
                valid: boolean()
            })
            .test({
                message: 'Please enter a valid phone number',
                test: value =>
                    value.number === undefined ||
                    value.number === '' ||
                    value.valid === true
            }),
        email: string().email('Please enter a valid email').default(''),
        rememberMe: boolean().default(false)
    }).test(
        'emailOrPhone',
        'Please enter either a phone number or email to receive a verification code.',
        value =>
            value.email.length > 0 ||
            (value.phone?.number !== undefined && value.phone.number.length > 0)
    )
)

const { errors, defineComponentBinds, handleSubmit, submitCount } = useForm({
    validationSchema: schema
})

const phoneValue = defineComponentBinds('phone', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})
const emailValue = defineComponentBinds('email', {
    validateOnModelUpdate: false,
    validateOnBlur: true
})
const rememberMe = defineComponentBinds('rememberMe')

const query = useRpQuery({ orgLevel: true })
const { mutate: loginByEmail, isPending: isLoggingInByEmail } = useMutation({
    mutationFn: (input: LoginByEmailInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation LoginByEmail($input: LoginByEmailInput!) {
                    loginByEmail(input: $input) {
                        __typename
                        ... on LoginByEmailResult {
                            token
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const { mutate: loginByPhone, isPending: isLoggingInByPhone } = useMutation({
    mutationFn: (input: LoginByPhoneInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation LoginByPhone($input: LoginByPhoneInput!) {
                    loginByPhone(input: $input) {
                        __typename
                        ... on LoginByPhoneResult {
                            token
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const isPending = computed(
    () => isLoggingInByEmail.value || isLoggingInByPhone.value
)

const onSubmit = handleSubmit(values => {
    if (values.phone.valid === true) {
        loginByPhone(
            {
                phone: values.phone.number,
                remember: values.rememberMe
            },
            {
                onSuccess: data => {
                    // If failure, show the errors
                    if (data.loginByPhone.__typename === 'ValidationError') {
                        // TODO: This
                        return
                    }
                    // Otherwise
                    else {
                        token.value = data.loginByPhone.token
                    }
                }
            }
        )
    } else if (values.email !== '') {
        loginByEmail(
            {
                email: values.email,
                remember: values.rememberMe
            },
            {
                onSuccess: data => {
                    // If failure, show the errors
                    if (data.loginByEmail.__typename === 'ValidationError') {
                        // TODO: This
                        return
                    }
                    // Otherwise
                    else {
                        token.value = data.loginByEmail.token
                    }
                }
            }
        )
    } else {
        // TODO: Handle error
        throw new Error()
    }
})

const toast = useToast()
const viewer = useViewer()
function done() {
    emit('close')
    toast.add({
        severity: 'success',
        summary: 'Sign In Complete',
        detail: viewer.value?.name ?? '',
        life: 3000
    })
}

// Do this here so that credentials.get is 100% called before the text message is sent
const twoFactorCode = ref('')
let ac = new AbortController()
onMounted(() => {
    if ('OTPCredential' in window) {
        navigator.credentials
            .get({
                otp: { transport: ['sms'] },
                signal: ac.signal
            })
            .then(otp => {
                twoFactorCode.value = otp.code
            })
            .catch(err => {
                if (err !== 'left-page') {
                    console.log(err)
                }
            })
    }
})
onUnmounted(() => {
    if ('OTPCredential' in window) {
        // Just give a reason to prevent warnings
        ac.abort('left-page')
        ac = undefined
    }
})

const { mutate: verifyGuest, isPending: isVerifyingGuest } = useMutation({
    mutationFn: (input: VerifyGuestInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyGuest($input: VerifyGuestInput!) {
                    verifyGuest(input: $input) {
                        __typename
                        ... on VerifyGuestResult {
                            result
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const recaptchaDisabled = computed(() => {
    return recaptchaToken.value === '' && !import.meta.env.DEV
})

function submitGuest() {
    verifyGuest(
        { token: recaptchaToken.value },
        {
            onSuccess: () => {
                emit('guest')
            }
        }
    )
}

function handleRecaptcha() {
    showRecaptcha.value = true
}
</script>

<style>
.p-input-icon-left,
.p-input-icon-right {
    position: relative;
    display: inline-block;
}

.p-input-icon-left > i:first-of-type {
    left: 0.75rem;
    color: #64748b;
}

.p-input-icon-left > input {
    padding-left: 2.5rem;
}

.p-input-icon-left.p-float-label > label {
    left: 2.5rem;
}

.p-input-icon-left > i,
.p-input-icon-left > svg,
.p-input-icon-right > i,
.p-input-icon-right > svg {
    position: absolute;
    top: 50%;
    margin-top: -0.5rem;
}
</style>
