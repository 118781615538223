<template>
    <div
        :id="recaptchaId"
        class="g-recaptcha"
        data-sitekey="6LeOcIUpAAAAACW5tY8Vb0jCdA0P64c4EqwS7KLD"
        :data-callback="successCallBackFunctionName"
        :data-expired-callback="expiredCallBackFunctionName"
    />
</template>

<script setup lang="ts">
import { useHead } from 'unhead'
import { onMounted, onUnmounted } from 'vue'
import { randomString } from '~/composables/support/util'

const recaptchaId = 'rphq_recaptcha_' + randomString()
const onloadFunctionName = recaptchaId + '_onload'
useHead({
    script: [
        {
            innerHTML:
                'var ' +
                onloadFunctionName +
                ` = function() {
                    window.grecaptcha.render(` +
                recaptchaId +
                `)
                }
            `
        },
        {
            src:
                'https://www.google.com/recaptcha/api.js?onload=' +
                onloadFunctionName +
                '&render=explicit',
            async: true,
            defer: true
        }
    ]
})

const emit = defineEmits<{
    (e: 'token', payload: string): void
}>()

const successCallBackFunctionName = recaptchaId + '_handleRecaptchaToken'
const expiredCallBackFunctionName = recaptchaId + '_handleRecaptchaExpired'
onMounted(() => {
    window[successCallBackFunctionName] = (token: string) => {
        emit('token', token)
    }
    window[expiredCallBackFunctionName] = () => emit('token', '')
})

onUnmounted(() => {
    window[successCallBackFunctionName] = undefined
    window[expiredCallBackFunctionName] = undefined
})
</script>
