<template>
    <form
        class="space-y-6"
        @submit="handleSubmit"
    >
        <div class="text-lg font-medium text-gray-600">
            We were unable to find an existing online account for you. To create
            an online account we just need some basic information about you:
        </div>

        <div class="text-sm">
            If you have an existing customer record with us, our staff can
            attach this to your online account when we see you next!
        </div>

        <div class="text-lg font-medium">
            <span class="text-red-600 font-semibold">IMPORTANT:</span> Please
            use YOUR OWN NAME to create your account even if you are a
            parent/guardian. You will be able to add other participants to your
            account when completing the waiver or booking.
        </div>

        <div ref="firstNameRef">
            <div>Full Name:</div>
            <div class="grid grid-cols-3 gap-3">
                <div>
                    <input-text
                        id="first_name"
                        v-model="firstName"
                        type="text"
                        class="w-full"
                        placeholder="First Name"
                        required
                    />
                    <small
                        v-if="showErrors && firstName.length < 2"
                        id="firstname-help"
                        class="text-red-600"
                        >2 characters required</small
                    >
                </div>

                <div>
                    <input-text
                        id="middle_name"
                        v-model="middleName"
                        type="text"
                        class="w-full"
                        placeholder="Middle Name (Optional)"
                    />
                </div>

                <div>
                    <input-text
                        id="last_name"
                        v-model="lastName"
                        type="text"
                        class="w-full"
                        placeholder="Last Name"
                        required
                    />
                    <small
                        v-if="showErrors && lastName.length < 2"
                        id="lastname-help"
                        class="text-red-600"
                        >2 characters required</small
                    >
                </div>
            </div>
        </div>

        <div v-if="useDateOfBirth">
            <div>Date of Birth:</div>
            <div class="flex gap-4">
                <dropdown-date-input
                    v-model:date="dateOfBirth"
                    :show-errors="showErrors"
                />
            </div>

            <div class="text-xs">
                We require date of birth to comply with relevant laws for online
                accounts.
            </div>
        </div>

        <prime-button
            type="submit"
            label="Next"
            class="w-full"
            :loading="isPending"
        />
    </form>
</template>

<script setup lang="ts">
import { onMounted, ref } from 'vue'
import PrimeButton from 'primevue/button'
import InputText from 'primevue/inputtext'
import { useMutation } from '@tanstack/vue-query'
import { useRpQuery } from '~/composables/graphql'
import type { FragmentType } from '~/resources/graphql'
import { graphql } from '~/resources/graphql'
import type { VerifyDateOfBirthInput } from '~/resources/graphql/graphql'
import { PossibleCustomerFragment } from '~/components/Verification/ChooseCustomer.vue'
import { useSetViewer } from '~/composables/use-login'
import { useShowAlert } from '~/composables/use-show-alert'
import { isSfrc } from '~/composables/use-org'
import DropdownDateInput from '~/components/Input/DropdownDateInput.vue'

const props = defineProps<{
    token: string
}>()

const emit = defineEmits<{
    (e: 'done'): void
    (
        e: 'multiple',
        payload: FragmentType<typeof PossibleCustomerFragment>
    ): void
    (e: 'createCustomer', payload: string): void
}>()

const firstNameRef = ref<HTMLDivElement>()
onMounted(() => {
    // Focus input on mounted
    firstNameRef.value?.querySelector('#first_name')?.focus?.()
})

const firstName = ref('')
const middleName = ref('')
const lastName = ref('')
const dateOfBirth = ref('')

const query = useRpQuery()
const { mutate, isPending } = useMutation({
    mutationFn: (input: VerifyDateOfBirthInput) =>
        query(
            graphql(/** @lang GraphQL */ `
                mutation VerifyDateOfBirth($input: VerifyDateOfBirthInput!) {
                    verifyDateOfBirth(input: $input) {
                        __typename
                        ... on VerifyDateOfBirthResult {
                            record {
                                ...ViewerFragment
                            }
                        }
                        ... on VerifyDateOfBirthCreateCustomerResult {
                            record {
                                ...ViewerFragment
                            }
                        }
                        ... on VerifyDateOfBirthMultipleResult {
                            ...PossibleCustomerFragment
                        }
                        ... on ValidationError {
                            fieldErrors {
                                message
                            }
                        }
                    }
                }
            `),
            {
                input
            }
        )
})

const showErrors = ref(false)
const useDateOfBirth = !isSfrc()
const showAlert = useShowAlert()
const setViewer = useSetViewer()
function handleSubmit(event: Event) {
    // Prevent browser submitting form
    event.preventDefault()

    if (
        (useDateOfBirth && dateOfBirth.value === '') ||
        firstName.value.length < 2 ||
        lastName.value.length < 2
    ) {
        showErrors.value = true
        return
    }

    mutate(
        {
            token: props.token,
            dateOfBirth: dateOfBirth.value === '' ? null : dateOfBirth.value,
            firstName: firstName.value,
            middleName: middleName.value,
            lastName: lastName.value
        },
        {
            onSuccess: data => {
                if (
                    data.verifyDateOfBirth.__typename ===
                    'VerifyDateOfBirthResult'
                ) {
                    // TODO: Check customer name is filled out
                    setViewer(data.verifyDateOfBirth.record)
                    emit('done')
                    return
                } else if (
                    data.verifyDateOfBirth.__typename ===
                    'VerifyDateOfBirthCreateCustomerResult'
                ) {
                    setViewer(data.verifyDateOfBirth.record)
                    emit('done')
                    return
                } else if (
                    data.verifyDateOfBirth.__typename ===
                    'VerifyDateOfBirthMultipleResult'
                ) {
                    emit('multiple', data.verifyDateOfBirth)
                    return
                }

                showAlert(
                    'Uh Oh!',
                    'Something went wrong. Please try again and contact the facility if the issue continues.'
                )
            }
        }
    )
}
</script>
